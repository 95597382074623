// import Home from "./Home";
//  import Service from "./Service";
// import Footer from "./Footer";
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";


import { Outlet} from "react-router-dom";

const Layout = () => {
    return (
      <div>
        <Navbar/>
        <Banner/>
        <Outlet/>
        <Footer/>
      </div>
    )
  };
  
  export default Layout;