import About from "./About";
import Contacts from "./Contacts";
import News from "./News";
// import Partners from "./Partners";
import Projects from "./Projects";
import Service from "./Service";
import Works from "./Works";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Home =() =>{
    return(
        <div className="mx-auto py-5">
          {/* <h1 className="text-3xl font-bold"> Home</h1> */}
          <div className="container text-center my-12">
            <h3 className="uppercase font-extrabold ">Social</h3>
            
            <div className='flex flex-row py-4'>
                <div className="w-full sm:w-1/3">
                  
                </div>
                <div className="w-full sm:w-1/3">
                {/* <FontAwesomeIcon icon={fas.faHouse} /> */}
                <a href="#instagram">
                <FontAwesomeIcon className="text-3xl text-red-400" icon={faInstagram} />
                </a>
                <a href="#facebook" className="px-5">
                <FontAwesomeIcon className="text-3xl text-blue-600" icon={faFacebook} />
                </a>
                <a href="#youtube">
                <FontAwesomeIcon className="text-3xl text-red-600" icon={faYoutube} />
                </a>
                
                </div>
                <div className="w-full sm:w-1/3">
                  
                </div>
            </div>
          </div>
          <About/>
          <Service/>
          <Projects/>
          <Works/>
          <News/>
          <Contacts/>
          {/* <Partners/> */}
          

        </div>
    )
};

export default Home;