import React from 'react'
import logo from '../images/aid-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const people = "2024"

export const Footer = () => {
  
  return (
    <>
      <div className='bg-slate-800 text-slate-50'>
          <div className='container mx-auto text-center'>
              <div className='flex flex-wrap p-5 py-20'>
                <div className="w-full sm:w-1/3 ">
                  <img src={logo} alt="logo" width={250} height="auto" className='p-5 hover:animate-spin mx-auto'/>
                </div>
                <div className="w-full sm:w-1/3 ">
                <h3 className='font-bold text-2xl p-5'>Quick Links</h3>
                  <ul>
                    <li><a href="#who-we-are">Who We Are</a></li>
                    <li ><a href="#what-we-do">What We Do</a></li>
                    <li><a href="#where-we-work">Where We Works</a></li>
                    <li ><a href="#partners">Our Partners & Sponsors</a></li>
                    {/* <li><a href="2">Support Us</a></li>
                    <li><a href="2">Contact Us</a></li>
                    <li><a href="2">News</a></li> */}
                  </ul>
                </div> 
                <div className="w-full  sm:w-1/3 ">
                  <h3 className='font-bold text-2xl p-5'>Social</h3>
                <div className="">
                {/* <FontAwesomeIcon icon={fas.faHouse} /> */}
                <a href="#instagram">
                <FontAwesomeIcon className="text-3xl text-red-400" icon={faInstagram} />
                </a>
                <a href="#facebook" className="px-5">
                <FontAwesomeIcon className="text-3xl text-blue-600" icon={faFacebook} />
                </a>
                <a href="#youtube">
                <FontAwesomeIcon className="text-3xl text-red-600" icon={faYoutube} />
                </a>
                
                </div>
                </div> 

              </div>
          </div>
      </div>
      <div className='text-red-500 hover:bg-red-600 hover:text-slate-50 text-center bg-slate-900 p-8'>
      <small>
        &copy; {people} AID-Ghana. All Rights Reserved | Powered By <a href="#1">GooCoTech</a>
      </small>
    </div>
    </>
    
  )
 
 
}




