import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import NoPage from "./components/NoPage";
import Service from "./components/Service";

import './index.css';
import './App.css';
import Home from "./components/Home"
import About from "./components/About"
import Works from "./components/Works"
import Projects from "./components/Projects"
import Partners from "./components/Partners"
import Contacts from "./components/Contacts"
import Donote from "./components/Donote"

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/who-we-are" element={<About />} /> 
        <Route path="/projects" element={<Projects/>} /> 
        <Route path="/what-we-do" element={<Service />} /> 
        <Route path="/where-we-work" element={<Works/>} /> 
        <Route path="/contact-us" element={<Contacts />} /> 
        <Route path="/partners" element={<Partners/>} />
       
      </Route>
      <Route path="/support-us" element={<Donote/>}/> 
      <Route path="*" element={<NoPage />} />
      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
