import React from 'react'

const About = () => {
  return (
    <div id='who-we-are' className='container mx-auto'>
      
      <h1 className='font-bold text-3xl p-5'>Who we are</h1>
       <div className='flex flex-wrap p-5 pb-20'>
        <p>Alliance for Inclusive Development (AID) is a Non-Governmental Organization (NGO) that exist to contribute to community development and sustainability through a not-for-profit initiatives and strategies.
It started operations in the Asutifi North district in 2017 as a community-based organization with the purpose of contributing to an inclusive, just, and equitable community that guarantees the dignity and development of marginalized populations.</p>
                <div className="w-full sm:w-1/2">
                   <h3 className='font-bold underline text-red-500 text-xl pt-8 pb-2'>Our mission</h3>
                  <p>
                  To promote, empower, and advocate for the inclusion and development of marginalized populations and foster activism in all spheres of society.
                  </p>
                </div>
               
                <div className="w-full  sm:w-1/2">
                   <h3 className='font-bold underline text-red-500 text-xl pt-8 pb-2'>Our vision</h3>
                  <p>
                  We envision an inclusive, just, and equitable community that guarantees the dignity and development of marginalized populations.
                  </p>
                </div> 
        </div>
    </div>
  )
}

export default About