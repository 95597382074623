import React from 'react'
import img1 from '../images/women-img-1.JPG'

const News = () => {
  return (
    <div id='news'>
      <h3 className='font-bold text-3xl p-5'>News & Updates</h3>
           
           <div className='flex flex-wrap'>
           <div className="w-full  sm:w-1/2">
                <img src={img1} alt="women-training"  className='rounded-xl '/>
           </div>
                <div className="w-full sm:w-1/2">
                 <h1 className='font-bold text-5xl text-slate-100 py-5 sm:text-6xl'>Advocacy & Leadership Training</h1>
                 <p className='py-9 px-5'> 75 women and 30 Persons with Disabilities (PWDs) from five communities in Asutifi North District were mobilized for this training.</p>

                 <button className='bg-slate-600 rounded-xl p-2 px-5 mb-9 m-3 text-center hover:animate-pulse'>Read More</button>
                </div>
            </div>
    </div>
  )
}

export default News