import React from 'react'


const Donote = () => {
  return (
    <div>
           
            <h3 className="py-12 my-12 text-5xl text-center">
                 Contact us on:  <a href="tel:+233548733739"><br />+233 548 733 739</a>
            </h3>
        
        </div>
  )
}

export default Donote