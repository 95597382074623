import React from 'react'

export const Banner = () => {
  return (
    <>
     <div  id='banner-text'>
        <h1 className='text-red-900 font-extrabold tracking-wide text-7xl md:text-9xl animate-pulse py-3 '>AID GHANA</h1>
        <h5 className='pb-6 text-slate-300 text-3xl sm:text-4xl'>Building a sustainable future together.</h5>
        <button className='bg-red-600 animate-bounce px-5 py-3 mt-12 rounded-lg hover:bg-slate-800'>
          <a className='text-slate-300 ' target='blank' href="support-us">
              <span>Support Us
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </span>
          </a>
        </button>
      
    </div>
    <div id='banner' className='p-8 py-10'> </div>
    </>
    
   
  )
}
