

const Service = () =>{
    return(
        <div id="what-we-do">
            <h3 className='font-bold text-5xl text-center p-5 ml-8 underline'>Our Targets</h3>
            <div className='flex flex-wrap p-5 pb-20 text-center '>
                <div className="w-full sm:w-1/3">
                 <h4 className="font-bold text-3xl p-4 text-red-900">Children & Youth</h4> 
                </div>
                <div className="w-full sm:w-1/3 ">
                <h4 className="font-bold text-3xl p-4 text-red-900">Persons with disabilities's (PWDs)</h4>  
                </div> 
                <div className="w-full  sm:w-1/3">
                 <h4 className="font-bold text-3xl p-4 text-red-900">Women</h4> 
                </div> 
            </div>
           
        </div>
    )
}

export default Service;