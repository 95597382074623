// import React from 'react'
// import React, { useState } from 'react';

// const Navbar = () => {
 
// }
import logo from '../images/aid-logo.png'

export const Navbar = () => {

  
  return (
    <nav className="bg-gray-800 p-8">
    <div className="container mx-auto flex justify-between items-center">
      {/* Logo */}
      <a href="/" className="text-white text-xl font-bold">
        <img src={logo} alt="logo" width={50} /></a>

      {/* Navbar links */}
      <div className="hidden md:block"> {/* Hide on small screens */}
        <a href="/#who-we-are" className="text-white ml-4">Who We Are</a>
        <a href="/#what-we-do" className="text-white ml-4">What We Do </a>
        <a href="/#where-we-work" className="text-white ml-4">Where We work</a>
        <a href="/#partners" className="text-white ml-4">Partners</a>
        <a href="/#projects" className="text-white ml-4">Projects</a>
        <a href="/#contact-us" className="text-white ml-4">Contact Us</a>
        {/* <a href="/" className="text-white ml-4">News</a> */}

      </div>

      {/* Mobile menu button */}
      <div className="md:hidden">
        {/* Hamburger menu icon (open/close) */}
        <button className="text-white focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
  </nav>

  
);
  
}


