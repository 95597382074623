import React from 'react'

const Works = () => {
  return (
    <>
   
    <div id='where-we-work'>
    <h3 className='font-bold text-3xl p-5'>Where we work</h3>
     <div className='flex flex-wrap p-5 pb-20'>
                <div className="w-full sm:w-1/2">
                  <h3 className='font-bold underline text-red-500 text-xl pt-8 pb-2'>Regions</h3>
                 <ul>
                    <li>Ahafo Region</li>
                    <li>Bono Region</li>
                    <li>Ashanti Region</li>
                  </ul>
                </div>
                 
                <div className="w-full  sm:w-1/2">
                 
                  <h3 className='font-bold underline text-red-500 text-xl pt-8 pb-2'>Communities</h3>

                  <ul>
                    <li>Duayaw Nkwanta</li>
                    <li>Kenyasi</li>
                    <li>Tepa</li>
                    <li>Ahafo Region</li>
                  </ul>
                </div> 
                </div>
    </div>
    </>
  )
}

export default Works