import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'

const Contacts = () => {
  return (
    <div id='contact-us' className='text-center'>
    <h3 className='font-bold text-3xl p-5'>Contacts Us</h3>
    <hr />
     <div className='flex flex-wrap p-5 pb-20'>
                <div className="w-full sm:w-1/1">
                <p className='font-bold underline pb-2'><FontAwesomeIcon className='font-bold text-red-600 text-xl px-2' icon={faLocation}/>Office Location</p>
                  <p> Kenyasi, besides Ntotroso road, <br /> Near Consar Residential Block,<br /> Asutifi North District - Ahafo Region</p>
                  <br /><br />
                  <p className=''><FontAwesomeIcon className='font-bold text-red-600 text-xl px-2' icon={faEnvelope}/><span className='font-bold'>Email:</span>  info@aidghana.org</p>
                  <p className='my-3'><FontAwesomeIcon className='font-bold text-red-600 text-xl px-2' icon={faPhone}/><span className='font-bold'>Call: </span> <a href="tel:+233548733739">+233 548 733 739</a></p>
                 
                  
                </div>
                 
                {/* <div className="w-full  sm:w-1/2">
                  form here
                </div>  */}
                </div>
    </div>
  )
}

export default Contacts