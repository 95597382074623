import { Banner } from "./Banner";
import {Footer} from "./Footer"


const NoPage = () =>{
    return(
        <div>
            <Banner/>
            <h3 className="py-12 my-12 text-5xl text-center">
                 404 - Page Not Found
            </h3>
          <Footer/>
        </div>
    )
};

export default NoPage;