import React from 'react'

const Projects = () => {
  return (
    <div id='projects'>
    <h3 className='font-bold text-3xl p-5'>Our Impacts</h3>
     <div className='flex flex-wrap p-5 pb-20 text-center'>
                <div className="w-full sm:w-1/3">
                  <h1 className='font-bold text-6xl p-4 text-red-900'>+ 600 </h1>
                  <p className='font-bold text-xl p-4 text-slate-900'>Women trained on Advocacy & Leadership.</p>
                </div>
                <div className="w-full sm:w-1/3 ">
                <h1 className='font-bold text-6xl p-4 text-red-900'>+ 20</h1> 
                 <p className='font-bold text-xl p-4 text-slate-900'>Communities sensitized on child's Rights and Protections.</p>
                </div> 
                <div className="w-full  sm:w-1/3">
                 <h1 className='font-bold text-6xl p-4 text-red-900'> + 400</h1>
                  <p className='font-bold text-xl p-4 text-slate-900'>PWDs have been empowered to be active in community development.</p>
                </div> 
              </div>
    </div>
  )
}

export default Projects