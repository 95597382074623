import React from 'react'

const Partners = () => {
  return (
    <div id='partners'>
    <h3 className='font-bold text-3xl p-5'>Our Partners</h3>
     <div className='flex flex-wrap p-5 py-20'>
                <div className="w-full sm:w-1/3">
                  Partner 1
                </div>
                <div className="w-full sm:w-1/3 ">
                  partner 2
                </div> 
                <div className="w-full  sm:w-1/3">
                  partner 3
                </div> 
                </div>
    </div>
  )
}

export default Partners